import React from "react"
import styled from "@emotion/styled"
import * as vars from "../styles/vars"
import Layout from "./layout"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import he from "he"
export const query = graphql`
  query($slug: String!) {
    allWordpressWpAiProyectos(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        slug
        content
        featured_media {
          source_url
        }
        acf {
          category_project
          description_project
          seo_descripcion
          images_project {
            image_project {
              alt_text
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Proyecto = ({
  data: {
    allWordpressWpAiProyectos: { nodes, edges },
  },
}) => {
  console.log(nodes)

  const { title, content, acf, featured_media } = nodes[0]

  return (
    //proyecto
    <Layout>
      <SEO
        title={`${he.decode(title)} - Ainoa Molina`}
        description={acf.seo_descripcion}
        image={featured_media.source_url}
      />

      <HeroProject className="hero-project">
        <div className="container">
          <p>
            <AniLink cover bg="#2CFFB9" to="/proyectos">
              VOLVER
            </AniLink>
          </p>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <h2 dangerouslySetInnerHTML={{ __html: acf.category_project }} />

          <p
            className="desc"
            dangerouslySetInnerHTML={{ __html: acf.description_project }}
          />
        </div>
      </HeroProject>
      <DetailProject className="projects project">
        <div className="container">
          <div className="projects-box archive">
            {acf.images_project.map(imagen => (
              <div className="project" key={imagen.image_project.id}>
                <Img
                  alt={imagen.image_project.alt_text}
                  fluid={imagen.image_project.localFile.childImageSharp.fluid}
                />
                {console.log(
                  imagen.image_project.localFile.childImageSharp.fluid
                )}
              </div>
            ))}
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {/* <div className="pagination">
                        <p>
                            <Link to={prev}>PROYECTO ANTERIOR</Link>
                        </p>
                        <p>
                            <Link to={next}>PRÓXIMO PROYECTO</Link>
                        </p>
                    </div> */}
        </div>
      </DetailProject>
    </Layout>
  )
}

export default Proyecto

const HeroProject = styled.section`
    position: relative;
    padding-top: 180px;
    padding-bottom: 90px;
    p{
        flex: 0 0 100%;
        ${vars.hoverColor}
        font-family: ${vars.barlow};
        &.desc {
            text-align: center;
            margin-top: 110px;
            margin-top: 40px;
            ${vars.sm}{
                margin-top: 50px;
            }
            ${vars.xl}{
                margin-top: 45px;
            }
            ${vars.xxl}{
                margin-top: 110px;
            }
        }
        a{
            text-align: left;
            display: inline-block;
            position: relative;
            &:after{
                content:"";
                position: absolute;
                width: 100%;
                height: 2px;
                left: 0;
                bottom: -8px;
                background: ${vars.green};
            }
        }
    }
    h1{
        flex: 0 0 100%;
        text-align: center;
        font-family: ${vars.barlow};
        margin-top: 40px;
        font-size: 1.625rem;
        line-height: 31px;
        ${vars.sm}{
            margin-top: 50px;
            font-size: 3.125rem;
            line-height: 60px;
        }
        ${vars.lg}{
            margin-top: 70px;
            font-size: 4.375rem;
            line-height: 84px;
        }
        ${vars.xl}{
            font-size: 4.375rem;
            line-height: 84px;
        }
        ${vars.xxl}{
            margin-top: 110px;
            font-size: 8.125rem;
            line-height: 156px;
        }
    }
    h2{
        flex: 0 0 100%;
        text-align: center;
        font-family: ${vars.barlow};
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 24px;
        @include sm{
            font-size: 1.875rem;
            line-height: 36px;
        }
        @include lg{
            font-size: 2.625rem;
            line-height: 50px;
        }
        @include xl{
            font-size: 2.625rem;
            line-height: 50px;
        }
        @include xxl{
            font-size: 4.5rem;
            line-height: 86px;
        }
    }
`

const DetailProject = styled.section`
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  ${vars.sm} {
    margin-bottom: 100px;
  }
  ${vars.md} {
    margin-bottom: 75px;
  }
  ${vars.lg} {
    margin-bottom: 70px;
  }
  ${vars.xl} {
    margin-bottom: 100px;
  }
  ${vars.xxl} {
    margin-bottom: 150px;
  }
  .description {
    flex: 0 0 100%;
    padding-bottom: 25px;
    ${vars.sm} {
      padding-bottom: 50px;
    }
    ${vars.lg} {
      padding-bottom: 70px;
    }
    ${vars.xl} {
      padding-bottom: 50px;
    }
    ${vars.xxl} {
      padding-bottom: 0px;
    }
    p {
      font-family: ${vars.barlow};
      font-weight: 300;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      color: ${vars.green};
    }
  }
  .projects-box {
    display: block;
    position: relative;
    margin-bottom: 25px;
    width: 100%;
    ${vars.md} {
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      &:after {
        display: none;
      }
      &.archive {
        flex-wrap: wrap;
        .project {
          margin-bottom: 30px;
        }
      }
    }
    ${vars.lg} {
      margin-bottom: 35px;
    }
    ${vars.xl} {
      margin-bottom: 50px;
    }
    ${vars.xxl} {
      margin-bottom: 75px;
    }
    .project {
      height: auto;
      position: relative;
      margin-bottom: 25px;
      z-index: 1;
      overflow: hidden;
      cursor: pointer;
      width: 100%;
      ${vars.md} {
        flex: 0 0 calc(50% - 15px);
        margin-bottom: 0;
      }
      img {
        width: 100%;
        height: auto;
        position: relative;
      }
    }
  }
  .pagination {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-between;
    flex-wrap: wrap;
    p {
      font-family: ${vars.barlow};
      font-weight: 300;
      font-size: 0.938rem;
      line-height: 18px;
      position: relative;
      display: inline-block;
      ${vars.sm} {
        font-size: 1.25rem;
        line-height: 24px;
        margin-bottom: 0;
      }
      ${vars.md} {
        font-size: 0.938rem;
        line-height: 18px;
      }
      ${vars.lg} {
        font-size: 1.125rem;
        line-height: 22px;
      }
      ${vars.xl} {
        font-size: 1.563rem;
        line-height: 30px;
      }
      ${vars.xxl} {
        font-size: 1.875rem;
        line-height: 36px;
      }
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -8px;
        background: ${vars.green};
      }
      ${vars.hoverColor}
    }
    ${vars.sm} {
      display: flex;
      justify-content: space-between;
    }
  }
`
